import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'; 
import Request from '../Request/Request';
import Store from '../Store/Store';
import Logo from '../Login/Logo';

const LoginDialog = props => (
  <div style={{ textAlign:'left', padding:'20px', width:'240px', height:'210px', backgroundColor:'white', color:'black', borderRadius:'4px', boxShadow:'0 5px 10px rgba(0,0,0,0.5)', boxSizing:'border-box' }} >
    <form onSubmit={ props.submitSignIn } >
      <label htmlFor="userid"><b>User ID</b></label>
      <br/>
      <input id="userid" name="userid" className="form-input" style={{ width:'176px', margin:'0 0 10px 0' }} />
      <br/>
      <label htmlFor="password"><b>Password</b></label>
      <br/>
      <input id="password" name="password" type="password" className="form-input" style={{ width:'176px', margin:'0 0 0 0' }}  />
      <br/>
      <input type="submit" className="open-account-button" value="Sign In" />
    </form>
  </div>
)

const ResetPasswordDialog = props => (
  <div style={{ textAlign:'left', padding:'20px', width:'240px', height:'180px', backgroundColor:'white', color:'black', borderRadius:'4px', boxShadow:'0 5px 10px rgba(0,0,0,0.5)', boxSizing:'border-box' }} >
    <h2 style={{ margin:'0 0 15px 0' }} >Reset Password</h2>
    <form onSubmit={ props.submitResetPassword } >
      <label htmlFor="userid"><b>User ID</b></label>
      <br/>
      <input id="userid" name="userid" className="form-input" style={{ width:'176px', margin:'0 0 10px 0' }} />
      <br/>
      <input type="submit" className="open-account-button" value="Reset" style={{ margin:'10px 5px 0 0' }} />
      <div className="open-account-button" style={{ margin:'10px 0 0 5px' }} onClick={ props.cancelResetPassword } >Cancel</div>
    </form>
  </div>
)

const ResetPasswordSentDialog = props => (
  <div style={{ textAlign:'left', padding:'20px', width:'240px', height:'180px', backgroundColor:'white', color:'black', borderRadius:'4px', boxShadow:'0 5px 10px rgba(0,0,0,0.5)', boxSizing:'border-box' }} >
    <h2 style={{ margin:'0 0 15px 0' }} >Reset Password Sent</h2>
    <p>A link has been sent to your email address.
    Please follow instructions to reset your password.</p>
    <button className="open-account-button" style={{ margin:'10px 0 0 0' }} onClick={ props.cancelResetPassword } >Close</button>
  </div>
)

const AccountFunctions = props => (
  <Fragment>
    <div style={{ width:'100%', padding:'20px 10px 10px 10px', textAlign:'center', color:'white', boxSizing:'border-box' }} >
      <span onClick={ props.showResetPassword } className="thin-underline-on-hover" style={{ margin:'10px' }} >
        Reset Password
      </span>
      <span style={{ margin:'10px', color:'rgba(255,255,255,0.5)' }} >|</span>
      <Link to="/register">
      <span className="thin-underline-on-hover" style={{ margin:'10px', color:'white' }} >Create Account</span>
      </Link>
    </div>
  </Fragment>  
)

class Login extends React.Component {

  constructor( props ) {
    super( props )
    this.state = { 
      tmp:null,
      dialog:'signin',
    }
    this.submitSignIn = this.submitSignIn.bind(this)
    this.showResetPassword = this.showResetPassword.bind(this)
    this.cancelResetPassword = this.cancelResetPassword.bind(this)
    this.submitResetPassword = this.submitResetPassword.bind(this)
  }

  submitSignIn( e ) {
    e.stopPropagation()
    e.preventDefault();
    let user = document.getElementById( 'userid' ).value
    let password = document.getElementById( 'password' ).value
    Request.post( 
      "signin", 
      { email:user, password:password },
      result => { 
        Store.set( 'user', result.data )
        console.log( 'login got user', result.data )
        this.setState( { tmp:null } )
        this.props.history.replace( '/home/' )
      },
      error => { 
        console.error( error )
      } 
    )

  }

  submitResetPassword( e ) {
    e.stopPropagation()
    e.preventDefault();
    let user = document.getElementById( 'userid' ).value
    Request.post( 
      "reset-password", 
      { email:user },
      result => { 
        Store.set( 'user', result.data )
        this.setState ( { dialog:'confirmation' } )
      },
      error => { 
        console.error( error )
        //this.setState ( { dialog:'confirmation' } )
      } 
    )
  }

  showResetPassword( e ) {
    this.setState ( { dialog:'reset' } )
  }

  cancelResetPassword( e ) {
    this.setState ( { dialog:'signin' } )
  }

  render() {
    return (
      <div style={{ position:'fixed', left:0, top:0, width:'100%', height:'100%', backgroundColor:'#345', color:'white', boxSizing:'border-box' }} >
        <div style={{ position:'fixed', left:'50%', top:'50%', transform:'translate(-50%, -50%)', boxSizing:'border-box' }} >

          <Logo/>

          { this.state.dialog === 'signin' && 
            <Fragment>
              <LoginDialog submitSignIn={ this.submitSignIn } />
              <AccountFunctions showResetPassword={ this.showResetPassword }  />
            </Fragment>
          }

          { this.state.dialog === 'reset' && 
            <ResetPasswordDialog 
              submitResetPassword={ this.submitResetPassword }
              cancelResetPassword={ this.cancelResetPassword }
            />
          }

          { this.state.dialog === 'confirmation' && 
            <ResetPasswordSentDialog 
              cancelResetPassword={ this.cancelResetPassword }
            />
          }

        </div>
      </div>
    )
  }
}

export default Login
