import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom'; 
import Request from '../Request/Request';
import Store from '../Store/Store';
import Utils from '../Utils/Utils';
import Logo from '../Login/Logo';
import ClientForm from '../Account/ClientForm';
import ThankyouDialog from '../Account/ThankyouDialog';

const AccountCreate = props => {

	const history = useHistory()

	const [ mounted, setMounted ] = useState( false )
	const [ errorText, setErrorText ] = useState( '' )
	const [ missingColumns, setMissingColumns ] = useState( {} )
	const [ formSaved, setFormSaved ] = useState( false )
	const [ time, setTime ] = useState( null )

	const mandatoryColumns = [
		'email',
		'corporate_id',
		'company_name',
		'first_name',
		'last_name',
		'phone_number',
		'country',
		'delivery_address',
		'invoicing_address'
	]

	const checkMandatory = ( data, mandatoryColumns ) => {
		let tmpMissingColumns = {}
		mandatoryColumns.map( r => {
			if ( !data.hasOwnProperty( r ) || data[ r ] === null || data[ r ] === ''  ) {
				tmpMissingColumns[ r ] = true
			}
		} )
		return Utils.flatten( tmpMissingColumns )
	}

	const getPage = section => {
		Request.get( 
			'pages-by-section/' + section, 
			result => { 
				console.log( 'Frontpage getPage', section, result.data[0] )
				Store.set( section, result.data[ 0 ] || {} )
				setTime( Date.now() )
			},
			error => { 
				console.error( 'error', error )
			} 
		)
	}

	useEffect( () => {
		if ( !mounted ) {
			getPage( 'new-client-message' )
			Store.set( 'client', {} )
			setTime( Date.now() ) // force render
			setMounted( true )
			return () => {}
		}
	} )

	const saveRow = () => {
		let postData = Utils.flatten( Store.get( 'client' ) )
		let m = checkMandatory( postData, mandatoryColumns )
		if ( !Utils.isEmpty( m ) ) {
			setMissingColumns( m )
			setErrorText( 'Please fill in all mandatory fields market with an asterisk (*)' )
			return false
		}

		Request.post( 
			'client-create', 
			postData, 
			result => {
				setFormSaved( true )
				//history.replace( '/login' )
			},
			error => { 
				console.error( error )
			} 
		)
	}

	const setValue = ( e, column ) => {
		Store.set( 'client' + '.' + column , e.target.value ) 
		setTime( Date.now() ) // force render
	}

	let data = Store.get( 'client' )
	let page = Store.get( 'new-client-message' )
	console.log( 'render-page', page )
	if ( !data || data === undefined ) return null

	return (
		<div style={{ position:'fixed', left:0, top:0, width:'100%', height:'100%', backgroundColor:'#345', color:'white', boxSizing:'border-box', overflowY:'scroll' }} >
			<div style={{ position:'absolute', left:'50%', top:'25px', transform:'translateX(-50%)', boxSizing:'border-box' }} >

				<Link to="/login">
					<div 
						style={{ position:'absolute', left:0, top:0, cursor:'pointer', transform:'translateY(-10px)', whiteSpace:'nowrap', userSelect:'none', fontSize:'16px', fontWeight:'500', color:'#fff', fontFamily:'Source Sans Pro', padding:'0 0 0 10px', boxSizing:'border-box' }} 
					>
						<i className="material-icons" style={{ transform:'translate(-2px, 6.4px)' }}>arrow_back</i>
						<div style={{ display:'inline-block', whiteSpace:'nowrap' }} >Back</div>
					</div>
				</Link>

				<Logo/>

				{ !formSaved && 
					<ClientForm
						data={ data }
						setValue={ setValue }
						saveRow={ saveRow }
						errorText={ errorText }
						missingColumns={ missingColumns }
					/>
					}

				{ formSaved && 
					<ThankyouDialog copy={ page.copy } />
				}

			</div>
		</div>
	)

}

export default AccountCreate

