import React from 'react';

const Field = props => {
  //console.log( 'Field', props.column, props.data[ props.column ] )
  return (
    <tr>
      <td style={{ verticalAlign:'top', padding:'10px 0' }} >{ props.title }</td>
      <td>
        <div 
          className="form-element-div"
          style={ props.style }
        >
          { props.data[ props.column ] && props.data[ props.column ].trim() }
        </div>
        </td>
    </tr>
  )
}

export default Field
