import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory  } from 'react-router-dom'; 
import parse from 'html-react-parser';
import Request from '../Request/Request';
import Store from '../Store/Store';
import countItems from '../Variants/countItems';
import Footer from '../Footer/Footer';

const Frontpage = props => {

	let user = Store.get( 'user' )
	if ( user === null || user === undefined ) {
		props.history.replace( '/login/' )
	}

	const history = useHistory()
	const [ time, setTime ] = useState( null )
	const [ frontpageFetched, setFrontpageFetched ] = useState( false )

	const getRows = section => {
		Request.get( 
			'pages-by-section/' + section, 
			result => { 
				//console.log( 'Frontpage getRows', section, result.data )
				Store.set( section, result.data || [] )
				setTime( Date.now() )
			},
			error => { 
				console.error( 'error', error )
			} 
		)
	}

	useEffect( () => {
		let selection = Store.get( 'selection' )
		let itemsCount = Store.get( 'itemsCount' )
		if ( selection && !itemsCount ) {
			countItems()
			return () => {}
		}
		if ( !frontpageFetched ) {
			getRows( 'frontpage' )
			setFrontpageFetched( true )
		}
	} )

	let frontpage = Store.get( 'frontpage' ) || []
	//let footer1 = Store.get( 'footer-1' ) || []

	let img = ''
	if ( frontpage && frontpage.length > 0 ) {
		img = 'https://showroom.gemmi.fi/assets/' + frontpage[ 0 ].img		
	}

	return (
		<div style={{ width:'100%', overflowX:'hidden', textAlign:'left', boxSizing:'border-box' }} >

		<img src={ img } style={{ width:'100%', height:'50vh', objectFit:'cover' }} />

		<div className="front-copy" style={{  }} >
		{ frontpage && frontpage.length > 0 && parse( frontpage[ 0 ].copy, {
				replace: function ( domNode ) {
					if ( domNode.attribs && domNode.attribs.href ) {
						//console.log( ' *** ', domNode.attribs.href, domNode, domNode.children[ 0 ].data );
						return <Link to={ domNode.attribs.href }><b>{ domNode.children[ 0 ].data }</b></Link>
					}					
				} 
		} ) }
		</div>

		<Footer/>

		</div>
	)

}

export default Frontpage