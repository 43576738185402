import React, { Fragment } from 'react';
import Request from '../Request/Request';
import Store from '../Store/Store';
import Utils from '../Utils/Utils';
import Cols from '../Data/ProductsColumns';
import parse from 'html-react-parser';
import ProductImages from '../Products/ProductImages';
import Variants from '../Variants/Variants';
import CollectionsPopup from '../Collections/CollectionsPopup';
import Field from '../Widgets/Field';
import Input from '../Widgets/Input';
import TextArea from '../Widgets/TextArea';
import DateInput from '../Widgets/DateInput';
import HtmlEditor from '../Widgets/HtmlEditor';
import Image from '../Widgets/Image';
import Toggle from '../Widgets/Toggle';

class ProductsUpdate extends React.Component {

  constructor( props ) {
    super( props )
    this.state = { 
      tmp:null,
      //id:this.props.match.params.id == 'new' ? Utils.uuidv4() : this.props.match.params.id ,
    }
    this.getRow = this.getRow.bind(this)
    this.returnToList = this.returnToList.bind(this)
    //this.updatedVariants = {}
  }

  componentDidMount() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  	this.getRow( this.props.match.params.id )
  }

  getRow( id ) {
  	Request.get( 
      Cols.rowApi + '/' + id , 
      result => { 
        let r = result.data[ 0 ]
        r.display_date = Utils.isoToDate( r.display_date )
        Store.set( Cols.key + '-' + id, result.data[ 0 ] )
        this.setState( { tmp:null } )
      },
      error => { 
        console.log( 'error', error )
      } 
  	)
  }

  returnToList() {
    let collection = this.props.match.params.collection
    console.log( 'returnToList', collection )
    this.props.history.replace( '/products/' + collection + '/' )
  }

  render() {

    let collection = this.props.match.params.collection
    let id = this.props.match.params.id
    let data = Store.get( Cols.key + '-' + id ) || {}

    if ( Utils.isEmpty( data ) ) return null

    let user = Store.get( 'user' )
    if ( user === null || user === undefined ) {
      this.props.history.replace( '/login/' )
    }

    //console.log( 'product data', data )

    return ( 
      <Fragment>

        <div style={{ textAlign:'left', fontSize:'12px', margin:'0 0 50px 5px' }} >

        <table style={{ margin:'0 10px 10px 10px', width:'550px', maxWidth:'800px' }} ><tbody>

          <tr>
            <td colSpan="2" >
              <div 
                onClick={ this.returnToList } 
                style={{ display:'inline-block', cursor:'pointer', transform:'translateY(0px)', whiteSpace:'nowrap', userSelect:'none', fontSize:'16px', fontWeight:'500', color:'#000', fontFamily:'Source Sans Pro', padding:'5px 0 0 0', boxSizing:'border-box' }} 
              >
                <i className="material-icons" style={{ transform:'translate(-2px, 6.4px)' }}>arrow_back</i>
                <span style={{ padding:'0', margin:'20px 0 0 0', textAlign:'left', fontSize:'20px', fontWeight:'500', boxSizing:'border-box' }}>
                { 'Style ' + data.code + ' ' + data.collection_title + ' ' + data.title.toLowerCase() }
                </span>
              </div>

            </td>
          </tr>

          <tr>
            <td colSpan="2" >
              <ProductImages product_id={ id } />
            </td>
          </tr>

          <tr>
            <td colSpan="2" className="preview" style={{ padding:'0 0 0 3px', fontSize:'14px' }} >
              { parse( data.copy || '' ) }
            </td>
          </tr>

        </tbody></table>

        <div style={{ padding:'5px 0 15px 15px', fontSize:'14px' }} >
          Minimum order quantity (MOQ): <b>{ data.moq }</b>
        </div>


        <table style={{ margin:'0 10px 10px 10px' }} ><tbody>
          <tr>
            <td colSpan="2" >
              <Variants
                product_id={ id }
                sizes={ data.sizes }
              />
            </td>
          </tr>
        </tbody></table>

        <table style={{ margin:'0 10px 10px 10px', width:'550px', maxWidth:'800px' }} ><tbody>
          <tr>
            <td style={{ verticalAlign:'middle' }}>
              <div 
                onClick={ this.returnToList } 
                style={{ display:'inline-block', cursor:'pointer', transform:'translateY(0px)', whiteSpace:'nowrap', userSelect:'none', fontSize:'16px', fontWeight:'500', color:'#000', fontFamily:'Source Sans Pro', padding:'0', boxSizing:'border-box' }} 
              >
                <i className="material-icons" style={{ transform:'translate(-2px, 6.4px)' }}>arrow_back</i>
                <div style={{ display:'inline-block', whiteSpace:'nowrap' }} >Back</div>
              </div>
            </td>
          </tr>
        </tbody></table>

        </div>

      </Fragment>
    )
  }

}

export default ProductsUpdate
