import React, { Fragment } from 'react';
import Utils from '../Utils/Utils';

const Td = props => {
  let formattedData = props.data
  if ( props.type === 'date' ) {
    formattedData = Utils.isoDateToFi( props.data )
  } else if ( props.type === 'boolean' ) {
    formattedData = <span className="material-icons">{ props.data === 't' ? 'check_box' : 'check_box_outline_blank' }</span>
  } else if ( props.type === 'image' ) {
    formattedData = <img 
      src={ 'https://showroom.gemmi.fi/assets/' + props.data } 
      style={{ width:'80px', height:'80px', objectFit:'cover', margin:'0 0 -4px 0', padding:'0' }}
    />
  }
  if ( props.type === "html_colour" ) {
    return (
      <td 
        style={{ 
          borderTop:'1px solid #aaa', 
          borderBottom:'1px solid #aaa', 
          borderLeft:props.borderLeft || '1px solid #aaa', 
          borderRight:props.borderRight || '1px solid #aaa', 
          textAlign:'center',
          verticalAlign:'middle',
          padding:'3px 0 0 0', 
          minWidth:'24px',
        }} 
      >
      { props.data && 
        <div style={{ display:'inline-block', width:'16px', height:'16px', borderRadius:'8px', border:'0.5px solid black', backgroundColor:props.data }} />
      }
      </td>
    )
  } else {
    return (
      <td 
        style={{ 
          borderTop:'1px solid #aaa', 
          borderBottom:'1px solid #aaa', 
          borderLeft:props.borderLeft || '1px solid #aaa', 
          borderRight:props.borderRight || '1px solid #aaa', 
          padding:props.type === 'image' ? '0' : '0 10px', 
          margin:'0', 
          width:props.width,
          maxWidth:props.maxWidth,
          borderSpacing:'0',
          whiteSpace:props.type === 'textarea' ? 'pre-line' : 'nowrap', 
          height:'28px',
          minHeight:'28px',
          lineHeight:'16px', 
          textAlign:props.type === 'boolean' ? 'center' : 'left',
          verticalAlign:'middle' 
        }} 
      >
        { formattedData }
      </td>
    )
  }
}

const Cols = props => (
  <Fragment>
  { props.dataSource.listColumns.map( col => {
    return (
      <Td
        key={ props.dataSource.key + '.' + col.key }
        type={ col.type }
        borderLeft={ col.borderLeft } 
        borderRight={ col.borderRight } 
        width={ col.width }
        maxWidth={ col.maxWidth }
        data={ props.dataRow[ col.key ] }
      />
    )
  } ) }
  </Fragment>
)

const TBody = props => (
  <tbody>
    { props.dbRows && props.dbRows.length > 0 && props.dbRows.map( ( dbRow, dbIndex ) => { 
      //console.log( 'tr key', dbRow.id )
      return (
        <tr 
          key={ dbRow.id } 
          onClick={ () => { if ( typeof props.openRow !== 'undefined' ) props.openRow( dbRow.id ) } }
          className="list-tr"
        >
          <Cols 
            dataSource={ props.dataSource } 
            dataRow={ dbRow } 
          />
        </tr>
      )
    } ) }
    { props.sumTr }
  </tbody>
)

export default TBody
