import React from 'react';

const Input = props => {
  let isMissing=false
  if ( props.missingColumns && props.missingColumns.hasOwnProperty( props.column ) && props.missingColumns[ props.column ] ) {
    isMissing=true
  }
  return (
    <tr>
      <td style={{ verticalAlign:'top', color:isMissing?'red':'black', padding:'10px 0', width:'130px', whiteSpace:'nowrap' }} ><b>{ props.title }{ props.mandatory ? ' *' : '' }</b></td>
      <td>
        <input 
          value={ props.data[ props.column ] || '' } 
          onChange={ e => { props.onChange( e, props.column ) } }
          className="form-input"
        />
      </td>
    </tr>
  )
}

export default Input
