import React, { Fragment } from 'react';
import Utils from '../Utils/Utils';
import Store from '../Store/Store';

const Td = props => {

  if ( props.type === "editable" ) {
    return (
      <td 
        style={{ 
          border:'2px solid #000', 
          padding:'0 5px', 
          cursor:'text',
          minWidth:'20px',
          backgroundColor:'#f8f6f0',
          whiteSpace:'nowrap', 
          height:'28px',
          minHeight:'28px',
          textAlign:'left',
          verticalAlign:'middle' 
        }} 
        contentEditable={true}
        suppressContentEditableWarning={true}
        onInput={ e => { props.onInput( e, props.column, props.id ) } }
      >
        { props.data }
      </td>
    )
  } else if ( props.type === "html_colour" ) {
    return (
      <td 
        style={{ 
          borderTop:'1px solid #aaa', 
          borderBottom:'1px solid #aaa', 
          borderLeft:props.borderLeft || '1px solid #aaa', 
          borderRight:props.borderRight || '1px solid #aaa', 
          textAlign:'center',
          verticalAlign:'middle',
          padding:'3px 0 0 0', 
          minWidth:'24px',
        }} 
      >
      { props.data && 
        <div style={{ display:'inline-block', width:'16px', height:'16px', borderRadius:'8px', border:'0.5px solid black', backgroundColor:props.data }} />
      }
      </td>
    )
  } else {
    return (
      <td 
        style={{ 
          borderTop:'1px solid #aaa', 
          borderBottom:'1px solid #aaa', 
          borderLeft:props.borderLeft || '1px solid #aaa', 
          borderRight:props.borderRight || '1px solid #aaa', 
          padding:'0 5px', 
          height:'28px',
          minHeight:'28px',
          verticalAlign:'middle',
          whiteSpace:'nowrap',
        }} 
      >
        { props.data }
      </td>
    )
  }

}

const Cols = props => (
  <Fragment>
  { props.dataSource.listColumns.map( col => {
    return (
      <Td
        key={ props.dataSource.key + '.' + col.key }
        type={ col.type }
        borderLeft={ col.borderLeft } 
        borderRight={ col.borderRight } 
        data={ props.dataRow[ col.key ] }
        id={ props.dataRow.id }
        column={ col.key }
        onInput={ props.onInput } 
        updatedRows={ props.updatedRows }
        rowNo={ props.rowNo }
        rowsLength={ props.rowsLength }
      />
    )
  } ) }

  { props.sizes && props.sizes.map( col => {
    return (
      <Td
        key={ props.dataSource.key + '-' + props.dataRow.id + '-' + col }
        type="editable"
        data={ Store.get( 'selection.' + props.dataRow.id + '.' + col ) }
        data2={ props.dataRow[ col ] }
        id={ props.dataRow.id }
        column={ col }
        onInput={ props.onInput } 
        updatedRows={ props.updatedRows }
        rowNo={ props.rowNo }
        rowsLength={ props.rowsLength }
      />
    )
  } ) }

  </Fragment>
)

const TBodyVariants = props => (
  <tbody>
    { props.dbRows.map( ( dbRow, dbIndex ) => { 
      return (
        <tr 
          key={ dbRow.id } 
          className="list-tr"
        >
          <Cols 
            dataSource={ props.dataSource } 
            //sizes={ JSON.parse( props.sizes || [] ) } 
            sizes={ props.sizes } 
            dataRow={ dbRow } 
            onInput={ props.onInput } 
            updatedRows={ props.updatedRows }
            rowNo={ dbIndex }
            rowsLength={ props.dbRows.length }
          />
        </tr>
      )
    } ) }
    { props.sumTr }
  </tbody>
)

export default TBodyVariants
