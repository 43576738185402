import React from 'react';
import { Link } from 'react-router-dom'; 
import Field from '../Widgets/Field';
import Input from '../Widgets/Input';
import TextArea from '../Widgets/TextArea';

const ClientForm = props => { 
	//console.log( 'ClientForm missingColumns', props.missingColumns )
	return (
	<div style={{ textAlign:'left', backgroundColor:'white', color:'black', marginBottom:'60px', padding:'10px', borderRadius:'4px', boxShadow:'0 5px 10px rgba(0,0,0,0.5)', boxSizing:'border-box' }} >

		<h2 style={{ padding:'15px 0 5px 10px', margin:'0', textAlign:'left', fontWeight:'500' }}>
		Apply for a Wholesale Account
		</h2>

		<p style={{ padding:'15px 0 5px 10px', margin:'0', fontSize:'14px', textAlign:'left' }}>
		Please fill in the information below and press the "Send" button. We will create a wholesale account for you and send the password by email after review.
		</p>

		<table style={{ padding:'15px 0 5px 10px' }} ><tbody>
			<Input title="Email" column="email" mandatory={ true } missingColumns={ props.missingColumns } onChange={ props.setValue } data={ props.data } />
			<Input title="Corporate Id" column="corporate_id" mandatory={ true } missingColumns={ props.missingColumns } onChange={ props.setValue } data={ props.data } />
			<Input title="VAT Number" column="vat_number" mandatory={ false } missingColumns={ props.missingColumns } onChange={ props.setValue } data={ props.data } />
			<Input title="Company Name" column="company_name" mandatory={ true } missingColumns={ props.missingColumns } onChange={ props.setValue } data={ props.data } />
			<Input title="First Name" column="first_name" mandatory={ true } missingColumns={ props.missingColumns } onChange={ props.setValue } data={ props.data } />
			<Input title="Last Name" column="last_name" mandatory={ true } missingColumns={ props.missingColumns } onChange={ props.setValue } data={ props.data } />
			<Input title="Phone Number" column="phone_number" mandatory={ true } missingColumns={ props.missingColumns } onChange={ props.setValue } data={ props.data } />
			<Input title="Country" column="country" mandatory={ true } missingColumns={ props.missingColumns } onChange={ props.setValue } data={ props.data } />
			<TextArea title="Delivery Address" column="delivery_address" rows="5" mandatory={ true } missingColumns={ props.missingColumns } onChange={ props.setValue } data={ props.data } />
			<TextArea title="Invoicing Address" column="invoicing_address" rows="5" mandatory={ true } missingColumns={ props.missingColumns } onChange={ props.setValue } data={ props.data } />
		</tbody></table>

		<div onClick={ props.saveRow } className="open-account-button" style={{ margin:'10px' }} >
			Create Account
		</div>
		<span style={{ color:'red', fontWeight:'bold' }} >{ props.errorText }</span>

	</div>
	)
}

export default ClientForm
