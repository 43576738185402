import React, { Fragment } from 'react';

class Image extends React.Component {

  constructor( props ) {
    super( props )
    this.state = { 
      tmp:null,
    }
  }

  render() {
    return (
      <Fragment>
      <tr>
        <td style={{ verticalAlign:'top', padding:'10px 0' }} colSpan="2" >
          <img 
              style={{ width:'404px', height:'404px', margin:'5px 0 -2px 4px', objectFit:'cover', borderRadius:'4px' }}
              src={ 'https://showroom.gemmi.fi/assets/' + this.props.data[ this.props.column ] } 
          />
        </td>
      </tr>
      </Fragment>
    )
  }

}


export default Image
