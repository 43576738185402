import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'; 
import Request from '../Request/Request';
import Store from '../Store/Store';
import Utils from '../Utils/Utils';
import Field from '../Widgets/Field';
import Input from '../Widgets/Input';
import TextArea from '../Widgets/TextArea';

const Account = props => {

	const history = useHistory()

	let user = Store.get( 'user' )
	if ( user === null || user === undefined ) {
		history.replace( '/login/' )
	}

	const [ mounted, setMounted ] = useState( false )
	const [ time, setTime ] = useState( null )

	useEffect( () => {
		if ( !mounted ) {
			Request.get( 
				"client/" + user.id, 
				result => { 
					let r = result.data[ 0 ]
					Store.set( 'client', r )
					setTime( Date.now() ) // force render
					setMounted( true )
				},
				error => { 
					console.error( error )
				} 
			)
			return () => {}
		}
	} )

	const saveRow = () => {
		let postData = Utils.flatten( Store.get( 'client' ) )
		//console.log( 'saveRow postData', postData )
		Request.post( 
			'client-update', 
			postData, 
			result => {
				history.replace( '/home' )
			},
			error => { 
				console.error( error )
			} 
		)
	}

	const setValue = ( e, column ) => {
		Store.set( 'client' + '.' + column , e.target.value ) 
		setTime( Date.now() ) // force render
	}

	let data = Store.get( 'client' )
	//console.log( 'render data', data )
	if ( !data || data === undefined ) return null

	return (
		<div style={{ textAlign:'left' }} >

			<h2 style={{ padding:'15px 0 5px 20px', margin:'0', textAlign:'left', fontWeight:'500', boxSizing:'border-box' }}>
			ACCOUNT
			</h2>

			<table style={{ padding:'15px 0 5px 20px' }} ><tbody>
				<Field title="Email" column="email" data={ data } />
				<Input title="Corporate Id" column="corporate_id" onChange={ setValue } data={ data } />
				<Input title="VAT Number" column="vat_number" onChange={ setValue } data={ data } />
				<Input title="Company Name" column="company_name" onChange={ setValue } data={ data } />
				<Input title="First Name" column="first_name" onChange={ setValue } data={ data } />
				<Input title="Last Name" column="last_name" onChange={ setValue } data={ data } />
				<Input title="Phone Number" column="phone_number" onChange={ setValue } data={ data } />
				<Input title="Country" column="country" onChange={ setValue } data={ data } />
				<TextArea title="Delivery Address" column="delivery_address" rows="5" onChange={ setValue } data={ data } />
				<TextArea title="Invoicing Address" column="invoicing_address" rows="5" onChange={ setValue } data={ data } />

			</tbody></table>

			<div onClick={ saveRow } className="open-account-button" style={{ margin:'10px 0 10px 20px' }} >
				Save Changes
			</div>

		</div>
	)

}

export default Account

