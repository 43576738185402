const OrderRowsColumns = { 	
	"key":"orderrows", 
	"pageTitle":"Order Rows", 
	"rowTitle":"Order Row", 
	"newRowTitle":"New Order Row", 
	"listApi":"orderrows/", 
	"rowApi":"orderrow", 
	"newApi":"orderrow", 
	"updateApi":"orderrow-update/", 
	"deleteApi":"orderrow-delete/", 
	"fi":"Tilausrivit", 
	"en":"Order Rows", 
	"type":"db", 
	"listRoute":"/orderrows/",
	"formRoute":"/orderrows/",
	"listColumns":[
		{ "key":"collection_title", "fi":"collection", "en":"Collection" },
		{ "key":"product_code", "fi":"Style", "en":"Style" },
		{ "key":"product_title", "fi":"Product", "en":"Product" },
		{ "key":"sku", "fi":"Sku", "en":"Sku" },
		{ "key":"length", "fi":"length", "en":"Length" },
		{ "key":"colour", "fi":"Väri", "en":"Fabric or Leather", "borderRight":"none" },
		{ "key":"html_colour", "fi":"#1", "en":"", "type":"html_colour", "borderLeft":"none" },
		{ "key":"colour2", "fi":"Väri2", "en":"Main fur Colour", "borderRight":"none" },
		{ "key":"html_colour2", "fi":"#2", "en":"", "type":"html_colour", "borderLeft":"none" },
		{ "key":"colour3", "fi":"Väri3", "en":"Fur Details", "borderRight":"none" },
		{ "key":"html_colour3", "fi":"#3", "en":"", "type":"html_colour", "borderLeft":"none" },
		{ "key":"size", "fi":"size", "en":"Size" },
		{ "key":"quantity", "fi":"Kpl", "en":"Qty" },
		{ "key":"unit_price", "fi":"Hinta", "en":"Price" },
		{ "key":"amount", "fi":"amount", "en":"Amount" },
	],
	"formRows":[
		{ "key":"product_id", "fi":"Tuote", "en":"Product Id" },
		{ "key":"orderrow_id", "fi":"Order Rowti", "en":"Order Row Id" },
		{ "key":"quantity", "fi":"Kpl", "en":"Quantity" },
		{ "key":"unit_price", "fi":"Hinta", "en":"Unit Price" },
	],
}

export default OrderRowsColumns

//colour, v.colour2, v.colour3 
