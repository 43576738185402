import React, { Fragment } from 'react';
import Request from '../Request/Request';
import Store from '../Store/Store';
import Cols from '../Data/CollectionsColumns';

class CollectionsPopup extends React.Component {

  constructor( props ) {
    super( props )
    this.state = { 
      tmp:null,
    }
    this.getRows = this.getRows.bind(this)
  }

  componentDidMount() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  	this.getRows()
  }

  getRows() {
  	Request.get( 
      Cols.listApi, 
      result => { 
        result.data.unshift( {} )
        Store.set( Cols.key, result.data )
        this.setState( { 
          tmp:null,
        } )
      },
      error => { 
        console.log( 'error', error )
      } 
  	)
  }

  render() {

    /*
    let user = Store.get( 'user' )
    if ( user === null || user === undefined ) {
      this.props.history.replace( '/login/' )
    }
    */

    let dbRows = Store.get( Cols.key )

    return ( 
    <tr>
      <td style={{ verticalAlign:'top', padding:'10px 0', width:'130px', whiteSpace:'nowrap' }} >{ this.props.title }</td>
      <td>
        <select 
          name="collections-select" 
          id="collections-select" 
          className="form-select"
          value={ this.props.data[ this.props.column ] || '' } 
          onChange={ e => { this.props.onChange( e, this.props.column ) } }
        >
        { dbRows && dbRows.length > 0 && dbRows.map( ( dbRow, dbIndex ) => { 
            return (
                <option 
                  key={ dbRow.id } 
                  value={ dbRow.id } 
                  className="form-option"
                >
                { dbRow.title }
                </option>
            )
        } ) }
        </select>
      </td>
    </tr>
    )
  }

}

export default CollectionsPopup
