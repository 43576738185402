import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom'; // must import Router, else switch doesn't work

import Request from './Request/Request';
import Store from './Store/Store';

import Menu from './Menu/Menu';
import Login from './Login/Login';
import PasswordReset from './PasswordReset/PasswordReset';
import Frontpage from './Frontpage/Frontpage';
import ProductsList from './Products/ProductsList';
import ProductsUpdate from './Products/ProductsUpdate';
import OrdersList from './Orders/OrdersList';
import OrdersUpdate from './Orders/OrdersUpdate';
import Selection from './Selection/Selection';
import Account from './Account/Account';
import AccountCreate from './Account/AccountCreate';
import Guides from './Guides/Guides';
import Page from './Pages/Page';

import './css/index.css';

const App = ( props ) => {

  let user = Store.get( 'user' )
  console.log( 'App user', user )
  //Store.clear()

  return (
    <Router basename={ process.env.PUBLIC_URL }>
      <Fragment>
        <div style={{ width:'100%', boxSizing:'border-box' }} >
          <Route exact path="/:route/" component={ Menu }/>
          <Route exact path="/:route/:collection/" component={ Menu }/>
          <Route exact path="/:route/:collection/:id/" component={ Menu }/>
        </div>
        <Switch>
          <Route exact path="/register/" component={ AccountCreate }/>
          <Route exact path="/login/" component={ Login }/>
          <Route exact path="/password-reset/" component={ PasswordReset }/>
          <Route exact path="/home/" component={ Frontpage }/>
          <Route exact path="/products/:collection/" component={ ProductsList }/>
          <Route exact path="/products/:collection/:id/" component={ ProductsUpdate }/>
          <Route exact path="/selection/" component={ Selection }/>
          <Route exact path="/orders/" component={ OrdersList }/>
          <Route exact path="/orders/:id/" component={ OrdersUpdate }/>
          <Route exact path="/account/" component={ Account }/>
          <Route exact path="/guides/" component={ Guides }/>
          <Route exact path="/page/:id/" component={ Page }/>
          {/*
            <Route exact path="/users/" component={ UserList }/>
            <Route exact path="/users/:id/" component={ UserUpdate }/>
          */}
          <Redirect from="*" to="/home/" />
        </Switch>
      </Fragment>
    </Router>
  ) 

}

export default App
