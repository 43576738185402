import Store from '../Store/Store';

const countItems = () => {
  let items = 0
  let selection = Store.get( 'selection' )
  //console.log( 'countItems() selection', selection )
  if ( selection ) {
    let variants = Object.values( selection )
    if ( variants && variants.length > 0 ) {
      variants.map( v => {
        let sizes = Object.values( v )
        sizes.map( s => {
          if ( s && Number( s ) > 0 ) {
            //console.log( 'items', items, Number( s ) )
            items = items + Number( s )
          }
        } )
      } )
    }
  }
  Store.set( 'itemsCount', items )
}

export default countItems