import React from 'react';
import ReactDOM from 'react-dom';
//import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import Store from './Store/Store'

Store.init( () => {
	Store.clear()
	ReactDOM.render((
	    <App/>
	), document.getElementById( "root" ) )
} )

// Bundling React to another app as a module / component / widget
// https://stackoverflow.com/questions/37239731/writing-embeddable-javascript-plugin-with-react-webpack
// https://frontarm.com/james-k-nelson/how-to-integrate-react-into-existing-app/