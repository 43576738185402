import React, { Fragment } from 'react';

const Th = props => {
  return (
    <th 
      colSpan={ props.colSpan }
      style={{ 
        borderTop:'1px solid #aaa', 
        borderBottom:'1px solid #aaa', 
        borderLeft:props.borderLeft || '1px solid #aaa', 
        borderRight:props.borderRight || '1px solid #aaa', 
        fontSize:'11px', 
        padding:'2px 10px', 
        textAlign:'left',
        whiteSpace:'nowrap' 
      }} 
    >
      { props.title }
    </th>
  )
}

const ColTitles = props => (
  <Fragment>
    { props.dataSource.listColumns.map( col => {
        return ( col.type === 'helptext' || col.type === 'title' ) ? null : (
          <Th
            key={ props.dataSource.key + '.' + col.key }
            borderLeft={ col.borderLeft } 
            borderRight={ col.borderRight } 
            title={ col.en }
          />
        )
      } ) 
    } 
  </Fragment>
)

const THead = props => (
  <thead>
    <tr>
      <ColTitles dataSource = { props.dataSource } />
    </tr>
  </thead>
)

export default THead

