import React, { Fragment, useEffect } from 'react';
import { useHistory  } from 'react-router-dom'; 
import Store from '../Store/Store';
import countItems from '../Variants/countItems';

const Guides = props => {

	let user = Store.get( 'user' )
	if ( user === null || user === undefined ) {
		props.history.replace( '/login/' )
	}

	const history = useHistory()

	const openRow = id => {
		history.push( '/product/' + id + '/' )
	}

	useEffect( () => {
		let selection = Store.get( 'selection' )
		let itemsCount = Store.get( 'itemsCount' )
		if ( selection && !itemsCount ) {
			countItems()
			return () => {}
		}
	} )

	return (
		<Fragment>

		<h2 style={{ textAlign:'left', margin:'15px 20px 15px 20px' }} >Size Guides</h2>

		<ul style={{ fontSize:'16px', textAlign:'left' }} >
		<li><a href="https://gemmi.fi/pages/size-guide-womens" target="_blank">Women's</a></li>
		<li><a href="https://gemmi.fi/pages/size-guide-mens" target="_blank">Men's</a></li>
		<li><a href="https://gemmi.fi/pages/size-guide-hats" target="_blank">Hats</a></li>
		</ul>

		<div style={{ fontSize:'14px', textAlign:'left', textAlign:'left', margin:'20px 20px 15px 20px' }} >Size guides are displayed from Gemmi consumer website and open in a new tab.</div>

		</Fragment>
	)

}

export default Guides