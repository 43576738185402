import React from 'react';
import { Link } from 'react-router-dom'; 
import parse from 'html-react-parser';

const ThankyouDialog = props => { 
	return (
	<div style={{ textAlign:'left', fontSize:'14px', backgroundColor:'white', color:'black', marginBottom:'60px', padding:'20px', borderRadius:'4px', boxShadow:'0 5px 10px rgba(0,0,0,0.5)', boxSizing:'border-box' }} >

		{/*
		<h2 style={{ padding:'15px 0 5px 10px', margin:'0', textAlign:'left', fontWeight:'500' }}>
		Thank You!
		</h2>

		<p style={{ padding:'15px 0 15px 10px', margin:'0', fontSize:'14px', textAlign:'left' }}>
		We have received you information. We will create a wholesale account for you and send the password by email after review.
		</p>
		*/}

		{ parse( props.copy ) }

		<Link to="/login">
			<div onClick={ props.saveRow } className="open-account-button" style={{ margin:'10px 0' }} >
				Close
			</div>
		</Link>

	</div>
	)
}

export default ThankyouDialog
