import React, { Fragment } from 'react';

const Th = props => {
  return (
    <th 
      colSpan={ props.colSpan }
      style={{ 
        borderTop:'1px solid #aaa', 
        borderBottom:'1px solid #aaa', 
        borderLeft:props.borderLeft || '1px solid #aaa', 
        borderRight:props.borderRight || '1px solid #aaa', 
        fontSize:'11px', 
        padding:'2px 5px', 
        textAlign:'left',
        whiteSpace:'nowrap' 
      }} 
    >
      { props.title }
    </th>
  )
}

const ColTitles = props => (
  <Fragment>
    { props.dataSource.listColumns.map( col => {
        return (
          <Th
            key={ props.dataSource.key + '.' + col.key }
            title={ col.en }
            borderLeft={ col.borderLeft } 
            borderRight={ col.borderRight } 
          />
        )
      } ) 
    } 

    { props.sizes && props.sizes.map( col => {
        return (
          <Th
            key={ props.dataSource.key + '.' + col }
            title={ col }
          />
        )
      } ) 
    } 

  </Fragment>
)

const THeadVariants = props => (
  <thead>
    <tr>
      <ColTitles 
        dataSource = { props.dataSource } 
        sizes = { props.sizes } 
      />
    </tr>
  </thead>
)

export default THeadVariants

