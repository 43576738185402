import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'; 
import Request from '../Request/Request';
import Store from '../Store/Store';
import Logo from '../Login/Logo';

const PasswordReset = () => (
  <div style={{ position:'fixed', left:0, top:0, width:'100%', height:'100%', backgroundColor:'#345', color:'white', boxSizing:'border-box' }} >
    <div style={{ position:'fixed', left:'50%', top:'50%', transform:'translate(-50%, -50%)', boxSizing:'border-box' }} >

      <Logo/>

      <div style={{ textAlign:'left', padding:'20px', width:'240px', height:'180px', backgroundColor:'white', color:'black', borderRadius:'4px', boxShadow:'0 5px 10px rgba(0,0,0,0.5)', boxSizing:'border-box' }} >
        <h2 style={{ margin:'0 0 15px 0' }} >Reset Password Confirmed</h2>
          <p>
            A new password has been sent to your email address.
          </p>
        <Link to="/login/">
          <button className="open-account-button" style={{ margin:'10px 0 0 0' }} >Close</button>
        </Link>
      </div>

    </div>
  </div>
)

export default PasswordReset
