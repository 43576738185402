import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory  } from 'react-router-dom'; 
import parse from 'html-react-parser';
import Request from '../Request/Request';
import Store from '../Store/Store';
import Footer from '../Footer/Footer';

const Page = props => {

	const history = useHistory()
	const [ time, setTime ] = useState( null )
	const [ pageId, setPageId ] = useState( null )
    let id = props.match.params.id

	let user = Store.get( 'user' )
	if ( user === null || user === undefined ) {
		props.history.replace( '/login/' )
	}

	const getRows = () => {
		Request.get( 
			'page/' + id, 
			result => { 
				console.log( 'page-' + id, result.data )
				Store.set( 'page-' + id, result.data || {} )
				setPageId( id )
				//setTime( Date.now() )
			},
			error => { 
				console.error( 'error', error )
			} 
		)
	}

	useEffect( () => {
		if ( id !== pageId ) {
			getRows( id )
		}
	} )

	console.log( 'page id ', id )
	let page = Store.get( 'page-' + id ) || []

	return (
		<Fragment>
		<div style={{ width:'100%', overflowX:'hidden', boxSizing:'border-box', padding:'10px 20px', textAlign:'left' }} >

		{ page && page.length > 0 && parse( page[ 0 ].copy, {
				replace: function ( domNode ) {
					// console.dir(domNode, { depth: null });
					if ( domNode.attribs && domNode.attribs.href ) {
						return <Link to={ domNode.attribs.href }> { domNode.children[0].data } </Link>
					}					
				} 
		} ) }

		</div>
		<Footer/>
		</Fragment>
	)

}

export default Page