import React, { Component } from 'react';
import RichTextEditor from 'react-rte';

const toolbarConfig = {
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'IMAGE_BUTTON', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'}
  ],
  BLOCK_TYPE_DROPDOWN: [
    {label: 'Normal', style: 'unstyled'},
    {label: 'Heading Large', style: 'header-one'},
    {label: 'Heading Medium', style: 'header-two'},
    {label: 'Heading Small', style: 'header-three'}
  ],
  BLOCK_TYPE_BUTTONS: [
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'}
  ]
}

class HtmlEditor extends Component {

  constructor( props ) {
    super( props )
    //console.log( 'constuctor', this.props.htmlValue )
    this.state = { 
      htmlValue: null,
      value: RichTextEditor.createEmptyValue()
    }
    this.onChange = this.onChange.bind(this)
  }

  componentDidMount() {
    if ( this.state.htmlValue === null && this.props.htmlValue ) {
      this.setState( { htmlValue:this.props.htmlValue, value:RichTextEditor.createValueFromString( this.props.htmlValue, 'html') } );
    }
  }

  componentDidUpdate() {
    if ( this.state.htmlValue === null && this.props.htmlValue ) {
      this.setState( { htmlValue:this.props.htmlValue, value:RichTextEditor.createValueFromString( this.props.htmlValue, 'html') } );
    }
  }

  onChange( value ) {
    this.setState( { value:value } );
    if ( this.props.onChange ) {
      this.props.onChange(
        value.toString( 'html' )
      );
    }
  }

  render () {
    return (
      <RichTextEditor
        className={ this.props.className }
        value={ this.state.value }
        onChange={ this.onChange }
        toolbarConfig={ toolbarConfig }
      />
    );
  }

}

export default HtmlEditor
