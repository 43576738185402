var Utils = function() {

	return {

		flatten: obj => {
			return JSON.parse(JSON.stringify( obj ))
		},

		escapeText: str => {
			let out = str.replace(/\\n/g, "\\n")
		    out = out.replace(/\\n/g, "\\n")
			out = out.replace(/\\'/g, "\\'")
			out = out.replace(/\\"/g, '\\"')
			out = out.replace(/\\&/g, "\\&")
			out = out.replace(/\\r/g, "\\r")
			out = out.replace(/\\t/g, "\\t")
			out = out.replace(/\\b/g, "\\b")
			out = out.replace(/\\f/g, "\\f");
			console.log( 'out', out )
            return out
		},

		format: ( amountIn, decimalCount = 2, decimal = ",", thousands = "." ) => {
			try {

				let amount = Number( amountIn )

				decimalCount = Math.abs(decimalCount);
				decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

				const negativeSign = amount < 0 ? "-" : "";

				let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
				let j = (i.length > 3) ? i.length % 3 : 0;

				return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
			} catch (e) {
				console.log(e)
			}
		},

		uuidv4: () => {
		  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
		    return v.toString(16);
		  });
		  // https://stackoverflow.com/questions/105034/how-to-create-guid-uuid
		},

		jsonEscape: str => {
		  if ( typeof str === 'undefined' ) return null
		  return str.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t");
		},

		jsonUnEscape: str => {
		  if ( typeof str === 'undefined' ) return null
		  return str.replace(/\\n/g, "\n").replace(/\\r/g, "\r").replace(/\\t/g, "\t");
		},

		replaceAll: ( str, find, replace ) => {
			if ( typeof str === 'undefined' ) return ''
			const escapeRegExp = (str) => {
	    		return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
			}
    		return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
		},

		// compare two objects
		isEquivalent: ( a, b ) => {
		    var aProps = Object.getOwnPropertyNames(a);
		    var bProps = Object.getOwnPropertyNames(b);
		    if (aProps.length !== bProps.length) {
		        return false;
		    }
		    for (var i = 0; i < aProps.length; i++) {
		        var propName = aProps[i];
		        if (a[propName] !== b[propName]) {
		            return false;
		        }
		    }
		    return true;
		},

		// empty object
		isEmpty: obj => {
		    for(var prop in obj) {
		        if(obj.hasOwnProperty(prop))
		            return false;
		    }
		    return JSON.stringify(obj) === JSON.stringify({});
		},

		// var is a number
		isNumber: o => {
		  return ! isNaN (o-0) && o !== null && o !== "" && o !== false;
		},

		// convetr tg ISO date to JavaScript Date
		isoToDate: iso => {
		    var date1 = iso.length > 10 ? iso.substring(0, iso.indexOf( '+' ) ) : iso
		    var date2 = date1.replace( new RegExp( '-', 'g' ) , '/' );
		    return Date.parse( date2 )
		},

		// convetr fi date to ISO Date
		fiDateToIso: datestring => {
			var datestringArr = datestring.split('.');
			if (datestringArr.length < 2) return false; // date not in three parts
			var dateIsoString = datestringArr[2] + '-' + datestringArr[1] + '-' + datestringArr[0]; // reverse date order
			var dateIso = Date.parse( dateIsoString ); // convert string to date
			if ( !( dateIso > 0 )) return false; // date not valid  
			return dateIsoString;
		},

		formatDatetoFi: ( today = new Date() ) => {
			return today.toLocaleString( 'fi-FI', { year: 'numeric', month: 'numeric', day: 'numeric' } );
		},

		// convetr ISO date to fi Date
		isoDateToFi: datestring => {
			//console.log( 'isoDateToFi', datestring )
			//console.log( 'isoDateToFi datestring', datestring )
			if ( typeof datestring === 'undefined' || datestring === null || datestring === false ) return ''
			var datestringArr = datestring.substr(0,10).split('-');
			if (datestringArr.length < 2) return false; // date not in three parts
			var dateFiString = datestringArr[2] + '.' + datestringArr[1] + '.' + datestringArr[0]; // reverse date order
			return dateFiString;
		},

		today: () => {
			return new Date();
		},

		todayIso: ( today = new Date() ) => {
			//let today = d || new Date();
			//console.log( today, new Date() )
			let dd = today.getDate();
			let mm = today.getMonth()+1; 
			let yyyy = today.getFullYear();
			if(dd<10) {
			    dd = '0'+dd
			} 
			if(mm<10) {
			    mm = '0'+mm
			} 
			return yyyy + '-' + mm + '-' + dd;
		},

		addDays: ( date, days ) => {
			var result = new Date( date );
			result.setDate( result.getDate() + days );
			return result;
		},

		// post a form, needed in banking integrations
		postAsForm: ( path, params, method, target ) => {
		    method = method || "post"; // Set method to post by default if not specified.
		    var form = document.createElement( "form" );
		    form.setAttribute( "method", method );
		    form.setAttribute( "action", path );
		    if ( typeof target != 'undefined') form.setAttribute( "target", target ); // e.g. '_blank'
		    for( var key in params ) {
		        if ( params.hasOwnProperty( key ) ) {
		            var hiddenField = document.createElement( "input" );
		            hiddenField.setAttribute( "type", "hidden" );
		            hiddenField.setAttribute( "name", key );
		            hiddenField.setAttribute( "value", params[key] );
		            form.appendChild( hiddenField );
		         }
		    }
		    document.body.appendChild(form);
		    form.submit();
		    document.body.removeChild(form);
		}

	} // return

}();

export default Utils

