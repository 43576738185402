import React, { Fragment } from 'react';
import Request from '../Request/Request';
import Store from '../Store/Store';
import Utils from '../Utils/Utils';
import Cols from '../Data/OrdersColumns';
import OrderRows from '../OrderRows/OrderRows';
import Field from '../Widgets/Field';
import Input from '../Widgets/Input';
import TextArea from '../Widgets/TextArea';
import SelectionTable from '../Selection/SelectionTable';

class OrdersUpdate extends React.Component {

  constructor( props ) {
    super( props )
    this.state = { 
      tmp:null,
    }
    this.getRow = this.getRow.bind(this)
    this.returnToList = this.returnToList.bind(this)
    this.saveRow = this.saveRow.bind(this)
    this.printRow = this.printRow.bind(this)
  }

  componentDidMount() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  	this.getRow( this.props.match.params.id )
  }

  getRow( id ) {
    if ( id != 'new' ) {
    	Request.get( 
        Cols.rowApi + '/' + id , 
        result => { 
          let r = result.data[ 0 ]
          //r.order_date = Utils.isoToDate( r.order_date )
          Store.set( Cols.key + '-' + id, result.data[ 0 ] )
          this.setState( { tmp:null } )
        },
        error => { 
          console.error( 'error', error )
        } 
    	)
    } else {
      Store.set( Cols.key + '-' + id, { published:'f', id:Utils.uuidv4() } )
      this.setState( { tmp:null } )
    }
  }

  returnToList() {
    this.props.history.replace( Cols.listRoute )
  }

  saveRow() {
    let id = this.props.match.params.id
    let data = JSON.parse( JSON.stringify( Store.get( Cols.key + '-' + id ) ) )
    data.order_date = Utils.todayIso( new Date( data.order_date ) )
    Request.post( 
      Cols.updateApi, 
      data, 
      result => {
        this.props.history.replace( Cols.listRoute )
      },
      error => { 
        console.error( 'error', error )
      } 
    )
  }

  printRow() {
    window.print()    
  }

  render() {

    let id = this.props.match.params.id
    let data = Store.get( Cols.key + '-' + id ) || {}
    let user = Store.get( 'user' )
    if ( user === null || user === undefined ) {
      this.props.history.replace( '/login/' )
    }

    let selection = JSON.parse( data.selection || '[]' )
    //console.log( 'data.selection', selection )

    return ( 
      <Fragment>

        <div style={{ textAlign:'left', fontSize:'12px', margin:'5px 0 50px 10px' }} >

        <table style={{ margin:'0 10px 10px 5px', width:'550px', maxWidth:'800px' }} ><tbody>

          <tr>
            <td colSpan="2" >
              <div 
                onClick={ this.returnToList } 
                style={{ display:'inline-block', cursor:'pointer', whiteSpace:'nowrap', userSelect:'none', fontSize:'12px', fontWeight:'500', color:'#000', verticalAlign:'middle', padding:'0 0 10px 0', boxSizing:'border-box' }} 
              >
                <i className="material-icons" style={{ transform:'translate(-2px, 6.4px)' }}>arrow_back</i>
                <div style={{ display:'inline-block', fontSize:'20px', whiteSpace:'nowrap' }} >{ Cols.rowTitle + ' ' + ( data.ordinal || '' ) }</div>
              </div>
            </td>
          </tr>

          <Field title="Order Number" column="ordinal" data={ data } />
          <Field title="Date" column="order_date" data={ data } />

          <Field title="Company" column="company_name" data={ data } />

          {/*

          <Field title="First Name" column="first_name" data={ data } />
          <Field title="Last Name" column="last_name" data={ data } />
          <Field title="Email" column="email" data={ data } />
          <Field title="Phone Number" column="phone_number" data={ data } />
          <Field title="Delivery Address" column="delivery_address" style={{ whiteSpace:'pre' }} data={ data } />
          <Field title="Invoicing Address" column="invoicing_address" style={{ whiteSpace:'pre' }} data={ data } />

          */}

          {/*
          <Field title="Selection" column="selection" data={ data } />
          */}

        </tbody></table>

          { selection && selection.map( p => { return (
            <div key={ p.productId } style={{ marginLeft:'-15px' }} >
            <SelectionTable
              key={ p.productCode }
              editable={ false }
              productId={ p.productId }
              productCode={ p.productCode }
              collectionCode={ p.collectionCode }
              title={ 'Style ' + p.productCode + ' ' + p.collectionTitle + ' ' + p.productTitle.toLowerCase() }
              moq={ p.productMoq }
              variants={ p.variants }
              totalQty={ p.totalQty }
              totalAmount={ p.totalAmount }
              data={ p.instructions }
              //onInput={ setCellValue }
            />
            </div>
          ) } ) }

        <table style={{ margin:'0 10px 10px 5px', width:'550px', maxWidth:'800px' }} ><tbody>

          {/*
          <tr>
            <td colSpan="2" >
              <OrderRows
                order_id={ id }
              />
            </td>
          </tr>
          */}

          <Field title="Total Amount" column="amount" data={ data } />

          <tr>
            <td colSpan="2" style={{ textAlign:'left' }} >
              <div onClick={ this.printRow } className="open-account-button" >
                Print
              </div>
            </td>
          </tr>

          </tbody></table>

        </div>

      </Fragment>
    )
  }

}

export default OrdersUpdate
