import React from 'react';
import logoImage from '../img/gemmi-logo-white.png';
const logoUrl = `${ logoImage }`

const Logo = () => (
  <div style={{ width:'100%', textAlign:'center', padding:'0 0 20px 0' }} >
    <img draggable="false" style={{ width:'120px', margin:'0' }} alt="Logo" src={ logoUrl }/>
  </div>
)

export default Logo
