import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'; 
import Utils from '../Utils/Utils';
import Store from '../Store/Store';
import Cols from '../Data/SelectionColumns';
import THead from '../Widgets/THead';
import TBody from '../Widgets/TBody';
import TdSum from '../Selection/TdSum';

const SelectionTable = props => {

let displayCols = Utils.flatten( Cols )
let dbRows = props.variants || []

//console.log( 'SelectionTable props.data', props.data )

let colCount = 8
if ( dbRows ) {
  let showColsObject = {}
  dbRows.map( r => {
    displayCols.listColumns.map( d => {
      if ( r[ d.key ] !== null && r[ d.key ] !== undefined && r[ d.key ] !== '' ) {
        showColsObject[ d.key ] = true
      }
    } )
  } )
  let tmpColumns = []
  displayCols.listColumns.map( d => {
    if ( showColsObject.hasOwnProperty( d.key ) ) {
      tmpColumns.push( d )
    }
  } )
  displayCols.listColumns = tmpColumns
  colCount = tmpColumns.length
}

return(
  <Fragment>
	<h3 style={{ padding:'15px 0 5px 20px', margin:'0', textAlign:'left', fontWeight:'500', boxSizing:'border-box' }}>
	  <Link className="underline-on-hover" to={ '/products/' + props.collectionCode + '/' + props.productId } > 
		  { props.title }
	  </Link>
	</h3>
	<div style={{ padding:'5px 0 15px 20px', fontSize:'14px' }} >
		Minimum order quantity (MOQ): <b>{ props.moq }</b>
	</div>

	<div style={{ padding:'0' }} >
	  <table style={{ fontSize:'12px', width:'290px', margin:'0 0 10px 20px', minWidth:'290px', maxWidth:'290px', borderCollapse:'collapse' }} >
	  <THead
	    dataSource={ displayCols }
	  />
	 <TBody 
	    dbRows={ props.variants || [] } 
	    dataSource={ displayCols }
	    sumTr={( 
	    	<Fragment>
		  	<tr>
		  		<td colSpan={ colCount - 2 }
		  			style={{  
						border:'1px solid #aaa', 
						padding:'5px 10px 6px 0',
						color:'red',
						fontWeight:'bold',
						textAlign:'right',
						verticalAlign:'top',
		  			}} 
	  			>
	  			{ props.totalQty < props.moq ? 'Selected quantity is less than MOQ!' : '' }
	  			</td>
				<TdSum total={ props.totalQty } />
				<TdSum total={ props.totalAmount } />
			</tr>
		  	<tr>
		  		<td style={{  
						border:'1px solid #aaa', 
						padding:'5px 10px 6px 0',
						fontWeight:'bold',
						textAlign:'right',
						verticalAlign:'top',
		  			}} >
		  			Instructions:
	  			</td>
		  		<td colSpan={ colCount - 1 } 
			        contentEditable={ props.editable }
			        suppressContentEditableWarning={ props.editable }
			        onInput={ !props.editable ? null : e => { props.onInput( e, 'instructions', props.productId ) } }
		  			style={{ 
						border:props.editable ? '2px solid black' : '1px solid #aaa', 
						whiteSpace:props.editable ? 'pre' : 'pre', 
						padding:'5px 10px',
						backgroundColor:props.editable ? '#f8f6f0' : null,
		  		}} >
		  		{ props.data }
		  		</td>
			</tr>

			{/*
		  	<tr>
		  		<td style={{  
						border:'1px solid #aaa', 
						padding:'5px 10px 6px 0',
						fontWeight:'bold',
						textAlign:'right',
						verticalAlign:'top',
		  			}} >
		  			Requested delivery time:
	  			</td>
		  		<td colSpan={ colCount - 1 } 
			        contentEditable={ props.editable }
			        suppressContentEditableWarning={ props.editable }
			        onInput={ !props.editable ? null : e => { props.onInput( e, 'delivery', props.productId ) } }
		  			style={{ 
						border:props.editable ? '2px solid black' : '1px solid #aaa', 
						whiteSpace:props.editable ? 'pre' : 'pre', 
						padding:'5px 10px',
						backgroundColor:props.editable ? '#f8f6f0' : null,
		  		}} >
		  		{ props.data }
		  		</td>
			</tr>
			*/}

	    	</Fragment>
    	)}
	  />
	  </table>
	</div>
  </Fragment>
)
}

export default SelectionTable
