const SelectionColumns = { 	
	"key":"selection", 
	"pageTitle":"SELECTION", 
	"fi":"SELECTION", 
	"en":"SELECTION", 
	"type":"db", 
	"listRoute":"/selection/",
	"listColumns":[
		//{ "key":"collection_title", "fi":"Collection", "en":"Collection" },
		//{ "key":"product_code", "fi":"Koodi", "en":"Style" },
		//{ "key":"product_title", "fi":"Tuote", "en":"Product" },
		//{ "key":"product_moq", "fi":"MOQ", "en":"MOQ" },
		{ "key":"sku", "fi":"SKU", "en":"SKU" },
		{ "key":"length", "fi":"Pituus", "en":"Length" },
		{ "key":"lining", "fi":"Fabric", "en":"Fabric" },
		{ "key":"colour", "fi":"Väri", "en":"Colour", "borderRight":"none" },
		{ "key":"html_colour", "fi":"#1", "en":"", "type":"html_colour", "borderLeft":"none" },
		{ "key":"colour2", "fi":"Väri2", "en":"Main fur Colour", "borderRight":"none" },
		{ "key":"html_colour2", "fi":"#2", "en":"", "type":"html_colour", "borderLeft":"none" },
		{ "key":"colour3", "fi":"Väri3", "en":"Fur Details", "borderRight":"none" },
		{ "key":"html_colour3", "fi":"#3", "en":"", "type":"html_colour", "borderLeft":"none" },
		//{ "key":"moq", "fi":"MOQ", "en":"MOQ" },
		{ "key":"size", "fi":"Koko", "en":"Size" },
		{ "key":"price", "fi":"Hinta", "en":"Price" },
		{ "key":"quantity", "fi":"Kpl", "en":"Qty" },
		{ "key":"amount", "fi":"Yhteensä", "en":"Amount" },
	],
}

export default SelectionColumns

