import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'; // must import Router, else switch doesn't work
import Request from '../Request/Request';
import Store from '../Store/Store';
import Utils from '../Utils/Utils';
import Cols from '../Data/VariantsColumns';
import THeadVariants from '../Widgets/THeadVariants';
import TBodyVariants from '../Widgets/TBodyVariants';
import countItems from '../Variants/countItems';

class Variants extends React.Component {

  constructor( props ) {
    super( props )
    this.state = { 
      tmp:null,
    }
    this.getRows = this.getRows.bind(this)
    //this.countItems = this.countItems.bind(this)
    this.setCellValue = this.setCellValue.bind(this)
  }

  componentDidMount() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  	this.getRows()
  }

  getRows() {
    this.setState( { 
      tmp:null,
    } )
  	Request.get( 
      Cols.listApi + this.props.product_id, 
      result => { 
        Store.set( Cols.key + '.' + this.props.product_id, result.data || [] )
        this.setState( { 
          tmp:null,
        } )
      },
      error => { 
        console.error( 'error', error )
      } 
  	)
  }

  setCellValue( e, column, rowId ) {
    Store.set( 'selection.' + rowId + '.' + column, e.currentTarget.textContent )
    countItems()
    this.setState( { tmp:null } )
  }


  render() {

    let user = Store.get( 'user' )
    if ( user === null || user === undefined ) {
      this.props.history.replace( '/login/' )
    }

    let displayCols = Utils.flatten( Cols )
    let dbRows = Store.get( Cols.key + '.' + this.props.product_id ) || []

    let colCount = 10
    if ( dbRows ) {
      let showColsObject = {}
      dbRows.map( r => {
        displayCols.listColumns.map( d => {
          if ( r[ d.key ] !== null && r[ d.key ] !== undefined && r[ d.key ] !== '' ) {
            showColsObject[ d.key ] = true
          }
        } )
      } )
      let tmpColumns = []
      displayCols.listColumns.map( d => {
        if ( showColsObject.hasOwnProperty( d.key ) ) {
          tmpColumns.push( d )
        }
      } )
      displayCols.listColumns = tmpColumns
      colCount = tmpColumns.length
    }

    //console.log( 'this.props.sizes', this.props.sizes )
    let sizesRaw = this.props.sizes || []
    let sizes = JSON.parse( sizesRaw )
    //let sizes = []

    return ( 
      <div style={{ textAlign:'left' }} >
        <div style={{ padding:'0' }} >
          <table style={{ fontSize:'12px', width:'100%', borderCollapse:'collapse' }} >
            <THeadVariants
              dataSource={ displayCols }
              sizes={ sizes }
            />
            <TBodyVariants 
              dbRows={ dbRows } 
              dataSource={ displayCols }
              //sizes={ this.props.sizes }
              sizes={ sizes }
              onInput={ this.setCellValue } 
              //updatedRows={ this.props.updatedRows }
              sumTr={ sizes.length > 2 && ( 
                <tr>
                  <td colSpan={ colCount } style={{  
                  border:'1px solid #aaa', 
                  }} />
                  <td colSpan={ sizes.length } style={{  
                    border:'1px solid #aaa',
                    padding:'5px',
                    width:( sizes.length * 20 ) + 'px',
                    minWidth:'100px'
                  }} >
                    <div style={{

                    }} >
                    Please update into these columns the quantities per variant and size to add products into 
                    <Link to="/selection"><b> Selection</b></Link>.
                    </div>
                  </td>

              </tr>
              )}
            />
        </table>
        </div>
      
        <div>
          { JSON.stringify( this.props.updatedRows ) }
        </div>
      
      </div>
    )
  }

}

export default Variants
