import axios from 'axios';
import Store from '../Store/Store';

var Request = function() {

	const apiRoot = "https://showroom.gemmi.fi/api/"

	let handleError = ( error, onError ) => {
		if ( typeof onError !== 'undefined' ) {
			onError( error )
		} else {
			console.error( error )
		}
	} 

	return {
		get:function( api, onSuccess, onError ){
			axios( {
		        method: 'GET',
		        url: apiRoot + api,
		        headers: {
		            "Content-Type": "application/json",
		    		"X-Token":Store.get( 'user.token' ),
		        },
			} )
			.then( onSuccess )
			.catch( error => { handleError( error, onError ) } )
		},
		xhr:function( method, api, data, onSuccess, onError ){
			axios( {
		        method: method,
		        url: apiRoot + api,
		        data: data ? JSON.stringify( data ) : {}, 
		        headers: {
		            "Content-Type": "application/json",
		    		"X-Token":Store.get( 'user.token' ),
		        },
	    	} )
			.then( onSuccess )
			.catch( error => { handleError( error, onError ) } )
		},
		post:function( api, data, onSuccess, onError ){
			//console.log( 'about to post', data )
			axios( {
		        method: 'POST',
		        url: apiRoot + api,
		        data: data ? JSON.stringify( data ) : {}, 
		        headers: {
		            "Content-Type": "application/json",
		    		"X-Token":Store.get( 'user.token' ),
		        },
	    	} )
			.then( onSuccess )
			.catch( error => { handleError( error, onError ) } )
		},
		put:function( api, data, onSuccess, onError ){
			axios( {
		        method: 'PUT',
		        url: apiRoot + api,
		        data: data ? JSON.stringify( data ) : {}, 
		        headers: {
		            "Content-Type": "application/json",
		    		"X-Token":Store.get( 'user.token' ),
		        },
	    	} )
			.then( onSuccess )
			.catch( error => { handleError( error, onError ) } )
		},
		delete:function( api, data, onSuccess, onError ){
			axios( {
		        method: 'DELETE',
		        url: apiRoot + api,
		        data: data ? JSON.stringify( data ) : {}, 
		        headers: {
		            "Content-Type": "application/json",
		    		"X-Token":Store.get( 'user.token' ),
		        },
	    	} )
			.then( onSuccess )
			.catch( error => { handleError( error, onError ) } )
		},
	}

}();

export default Request

