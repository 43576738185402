import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom'; 
import Request from '../Request/Request';
import Store from '../Store/Store';
import Utils from '../Utils/Utils';
import SelectionTable from '../Selection/SelectionTable';

const Selection = props => {

	const history = useHistory()

	let user = Store.get( 'user' )
	if ( user === null || user === undefined ) {
		history.replace( '/login/' )
	}

	const [ products, setProducts ] = useState( null )
	const [ time, setTime ] = useState( null )

	const clearSelection = ( route ) => {
		Store.set( 'selection', {} )
		Store.set( 'currentProducts', null )
	    Store.set( 'itemsCount', null )
	    //console.log( 'clearSelection selection', Store.get( 'selection' ) )
		//history.replace( route || '/products/' )
		history.replace( '/products/' )
	}

	const submitSelection = ( route ) => {
		Store.set( 'selection', {} )
		Store.set( 'currentProducts', null )
	    Store.set( 'itemsCount', null )
	    //console.log( 'clearSelection selection', Store.get( 'selection' ) )
		history.replace( route || '/products/' )
		//history.replace( '/products/' )
	}

	const setCellValue = ( e, column, rowId ) => {
		Store.set( 'selection.' + rowId + '.' + column, e.currentTarget.textContent )
	}

	const postOrder = () => {
	    let user = Store.get( 'user' )
		let s = Store.get( 'selection' ) // for getting instructions
		let productsToSend = Utils.flatten( products )
		let order_rows = []
		productsToSend.map( p => {
			p.instructions = s.hasOwnProperty( p.productId ) ? ( s[ p.productId ].instructions || '' ) : '' 
			p.variants.map( v => {
				order_rows.push( {
					product_id:p.productId,
					variant_id:v.variant_id,
					quantity:v.quantity,
					size:v.size
				} )
			} )
		} )
		let order = {
			client_id:user.id,
			selection:productsToSend,
			order_rows:order_rows
		}
		Request.post( 
			"order",
			order, 
			result => { 
				console.log( 'order result.data', result.data )
				submitSelection( '/orders/' + result.data[ 0 ].id )
			},
			error => { 
				console.error( 'error', error )
			} 
		)
	}

	useEffect( () => {
		if ( !products ) {
			let v = Store.get( 'currentProducts' )
			if ( v ) {
				setProducts( v )
			}
			let s = Store.get( 'selection' )
			if ( s ) {
			Request.get( 
				"all-variants", 
				result => { 
					let variants = result.data.filter( r => { return s.hasOwnProperty( r.id ) })
					let currentProducts = []
					let currentProductCode = null
					variants.map( v => {
						if ( v.product_code !== currentProductCode ) {
							currentProducts.push( {
								collectionTitle:v.collection_title,
								collectionCode:v.collection_code,
								productId:v.product_id,
								productCode:v.product_code,
								productTitle:v.product_title,
								productMoq:v.product_moq,
								totalQty:0,
								totalAmount:0,
								variants:[]
							} )
							currentProductCode = v.product_code
						}
					} )
					// iterate products and add variants
					currentProducts.map( p => {
						variants.map( r => {
							if ( r.product_code === p.productCode ) {
								let sizes = Object.keys( s[ r.id ] )
								let quantities = Object.values( s[ r.id ] )
								sizes.map( ( a, i ) => {
									if ( Number( quantities[ i ] ) > 0 ) {
										let qr = Utils.flatten( r )
										qr.variant_id = qr.id
										qr.id = p.productCode + '-' + qr.id + '-' + a + '-' + i
										qr.size = a
										qr.quantity = Number( Utils.flatten( quantities[ i ] ) )
										qr.amount = qr.quantity * Utils.flatten( qr.price )
										p.totalQty = p.totalQty + qr.quantity
										p.totalAmount = p.totalAmount + qr.amount
										p.variants.push( qr )
									}
								} )

							}
						} )
					} )
					setProducts( currentProducts )
					Store.set( 'currentProducts', currentProducts || [] )
				},
				error => { 
					console.error( 'error', error )
				} 
			)
			}
			return () => {}
		}
	} )

	return (
		<Fragment>
	      <div style={{ textAlign:'left' }} >

			<h2 style={{ padding:'15px 0 5px 20px', margin:'0', textAlign:'left', fontWeight:'500', boxSizing:'border-box' }}>
			  SELECTION
			</h2>

	      	{ products && products.map( p => { return (
		      	<SelectionTable
		      		key={ p.productCode }
					editable={ true }
		      		productId={ p.productId }
		      		productCode={ p.productCode }
		      		collectionCode={ p.collectionCode }
		      		title={ 'Style ' + p.productCode + ' ' + p.collectionTitle + ' ' + p.productTitle.toLowerCase() }
		      		moq={ p.productMoq }
		      		variants={ p.variants }
		          	totalQty={ p.totalQty }
		          	totalAmount={ p.totalAmount }
		      		data={ Store.get( 'selection.' + p.productId + '.' + 'instructions' ) }
		          	onInput={ setCellValue }
		      	/>
      		) } ) }

	      	{ products && 
				<div style={{ padding:'0 0 15px 20px', fontSize:'14px', textAlign:'left' }} >
					Minimum order quantity (MOQ) per product can include different colours and sizes.
				</div>
			}

	      </div>

	    { products && 
			<Fragment>
		        <table style={{ margin:'0 10px 10px 15px', width:'550px', maxWidth:'800px' }} ><tbody>
		          <tr>
		            <td style={{ verticalAlign:'middle', textAlign:'left' }}>
		              <div 
		                onClick={ clearSelection } 
		                className="delete-account-button" 
		                style={{ margin:'0' }} 
		              >
		                Clear Selection
		              </div>
		            </td>
		            <td style={{ verticalAlign:'middle', textAlign:'right' }}>
		              <div 
		                onClick={ postOrder } 
		                className="open-account-button" 
		                style={{ margin:'0' }} 
		              >
		                Order
		              </div>
		            </td>
		          </tr>
		      	</tbody></table>

			</Fragment>
      	}

	    { !products && 
	    	<div style={{ textAlign:'left', fontSize:'16px', margin:'20px 20px' }} >
	    		Selection is empty. <br/><br/>
	    		Please add products into selection by entering quantities by variant and size into tables under products:
				<ul>
				<li><Link to="/products/womens/">Women's</Link></li>	    		
				<li><Link to="/products/mens/">Men's</Link></li>	    		
				<li><Link to="/products/accessories/">Accessories</Link></li>	    		
				</ul>
	    	</div>
	    }

		</Fragment>
	)

}

export default Selection

