import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'; 
import Request from '../Request/Request';
import Store from '../Store/Store';
import Utils from '../Utils/Utils';

import logoImage from '../img/gemmi-logo-white.png';
const logoUrl = `${ logoImage }`

const MenuItem = props => {
  let itemClass = 'menu-item'
  if ( props.item === props.route || props.item === ( props.route + '/' + props.collection ) ) {
    itemClass += ' menu-selected'
  }
  return (
    <Link 
      draggable="false" 
      to={ '/' + props.item + '/' }
      className={ itemClass }
    >
    <div style={{ display:'inline-block', position:'relative', textTransform:'uppercase' }} >
    { props.title }
    { props.item === 'selection' && props.itemsCount &&
    <div style={{ position:'absolute', top:0, right:0, height:'11px', backgroundColor:'red', borderRadius:'8px', color:'white', fontWeight:'700', fontSize:'10px', padding:'1px 5px', lineHeight:'10px', transform:'translate(15px,-10px)', boxShadow:'0 2px 4px rgba(0,0,0,0.5)' }} >
      { props.itemsCount }
    </div>
    }
    </div>
    </Link>
  )
}

class Menu extends React.Component {

  constructor( props ) {
    super( props )
    this.state = { 
      tmp:null,
    }
    this.signOut = this.signOut.bind(this)
    this.getRows = this.getRows.bind(this)
  }

  signOut() {
    Store.set( 'user', null )
    this.props.history.replace( '/login/' )
    //console.log( 'signOut' )
  }

  componentDidMount() {
    this.getRows()
  }

  getRows() {
    this.setState( { 
      tmp:null,
    } )
    Request.get( 
      'collections', 
      result => { 
        Store.set( 'collections', result.data || [] )
        this.setState( { 
          tmp:null,
        } )
      },
      error => { 
        console.log( 'error', error )
      } 
    )
  }

  render() {

    let route = this.props.match.params.route
    let collection = this.props.match.params.collection
    let collections = Store.get( 'collections' ) || []
    //console.log( 'Menu route', route, collection )
    let user = Store.get( 'user' )

    if ( route === 'login' || route === 'register' || route === 'password-reset' ) {
      return null
    } else {
      return (
        <Fragment>

          <div style={{ position:'fixed', top:0, left:0, width:'100%', zIndex:101, boxSizing:'border-box' }} >

          <div style={{ width:'100%', padding:'0 10px', textAlign:'left', backgroundColor:'#000', color:'white', boxSizing:'border-box' }} >
            <Link 
              draggable="false" 
              to={ '/home/' }
            >
              <img draggable="false" style={{ width:'100px', margin:'20px 0 12px 10px', boxSizing:'border-box' }} alt="Logo" src={ logoUrl }/>
            </Link>
            <div className="menu-user">
              <div style={{ display:'inline-block', margin:'0 10px 0 0', textAlign:'right', lineHeight:'16px', transform:'translateY(-8px)' }} >
              { user && ( user.company_name || ( user.first_name + ' ' + user.last_name ) ) }
              </div>
              <span className="material-icons">account_box</span>
            </div>
            <Link 
              draggable="false" 
              to={ '/login/' }
              className="signout"
            >
              SIGN OUT
            </Link>
          </div>

          <div style={{ width:'100%', padding:'0 10px', textAlign:'left', backgroundColor:'#a79a80', color:'black', borderBottom:'1px solid rgba(0,0,0,0.5)', boxShadow:'0 3 12px rgba(0,0,0,0.35)', boxSizing:'border-box' }} >
            <MenuItem route={ route } item="home" title="HOME" />

            {/*
            <MenuItem route={ route } collection={ collection } item="products/womens" title="WOMEN'S" />
            <MenuItem route={ route } collection={ collection } item="products/mens" title="MEN'S" />
            <MenuItem route={ route } collection={ collection } item="products/accessories" title="ACCESSORIES" />
            <MenuItem route={ route } collection={ collection } item="products/puffer" title="PUFFER COATS" />
            */}

            { collections && collections.length > 0 && collections.map( ( a, i ) => (
              <MenuItem key={ i } route={ route } collection={ collection } item={ "products/" + a.code } title={ a.title } />
            ) ) }

            
            <MenuItem route={ route } item="selection" title="SELECTION" itemsCount={ Store.get( 'itemsCount' ) } />
            <MenuItem route={ route } item="orders" title="ORDERS" />
            <MenuItem route={ route } item="account" title="ACCOUNT" />
            <MenuItem route={ route } item="page/f728e27e-20b8-4528-9a59-7c066277b363" title="GUIDES" />
          </div>

          </div>

          <div style={{ width:'100%', height:'80px', boxSizing:'border-box' }} />

        </Fragment>
      )
    }

  }

}

export default Menu
