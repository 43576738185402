import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom'; 
import Request from '../Request/Request';
import Store from '../Store/Store';

/*
<video style={{ width:'200px', border:'1px dashed #999', padding:'10px', borderRadius:'4px' }} controls>
          <source src={ props.dir + props.file } type="video/mp4" />
        </video>
*/

const ImageInFullScreen = props => {
  //const fileExtension = props.file.split(".").pop().toLowerCase()
  return (
    <Fragment>
    <div 
      style={{ position:'fixed', zIndex:'100', left:0, top:0, width:'100%', height:'100%', margin:'0', backgroundColor:'rgba(0,0,0,0.85)' }} 
      //onClick={ props.hideImageFullScreen }
    >

    <img 
		src={ props.dir + props.file } 
		style={{ position:'fixed', zIndex:'101', left:'50%', top:0, transform:'translateX(-50%)', height:'100%' }}
	/>
   

    </div>
    <div 
      style={{ position:'fixed', zIndex:'102', left:'50%', top:0, transform:'translateX(-50%)', padding:'20px', margin:'20px', borderRadius:'10px', color:'white', fontSize:'18px', backgroundColor:'rgba(0,0,0,0.25)'  }} 
    >
      { props.file }
    </div>

      <div 
        onClick={ props.hideImageFullScreen } 
        style={{ position:'fixed', zIndex:'102', left:0, top:0, padding:'10px 20px 20px 20px', margin:'20px', backgroundColor:'rgba(0,0,0,0.25)', borderRadius:'10px', cursor:'pointer', color:'white', whiteSpace:'nowrap', userSelect:'none', fontSize:'16px', fontWeight:'500', verticalAlign:'middle', boxSizing:'border-box' }} 
      >
        <i className="material-icons" style={{ transform:'translate(-2px, 6.4px)' }}>arrow_back</i>
        <div style={{ display:'inline-block', whiteSpace:'nowrap' }} >Back</div>
      </div>

    </Fragment>
  )
}

const ProductImages = props => {

	const ref = useRef()

	const history = useHistory()
	let user = Store.get( 'user' )
	if ( user === null || user === undefined ) {
		history.replace( '/login/' )
	}

	const [ dir, setDir ] = useState( null )
	const [ files, setFiles ] = useState( [] )
	const [ image, setImage ] = useState( null )
	const [ fullScreen, setFullScreen ] = useState( false )

	useEffect( () => {
		// cache
		let d = Store.get( 'dir-' + props.product_id )
		let f = Store.get( 'files-' + props.product_id )
		//console.log( 'files cache', props.product_id, d, f )
		if ( d && f ) {
			setDir( d )
			setFiles( f )
			setImage( f[ 0 ] )
		}

		// refresh
		Request.get( 
			"files/" + props.product_id , 
			result => { 
				console.log( 'files data', result.data, props.product_id )
				setDir( result.data.dir )
				setFiles( result.data.files )
				setImage( result.data.files[ 0 ] )
				Store.set( 'dir-' + props.product_id, result.data.dir )
				Store.set( 'files-' + props.product_id, result.data.files )
			},
			error => { 
				console.log( 'error', error )
			} 
		)
		// unmount and cleanup
		return () => {}
	}, [ props.product_id ] )

	let imageExtension = '' 
	if ( image ) {
		imageExtension = image.split(".").pop().toLowerCase()	
	} 

	useEffect( () => {
		if ( imageExtension === 'mp4' ) {
			ref.current.play()			
		}
		// unmount and cleanup
		return () => {}
	}, [ image ] )

	if ( !dir || !image ) return null

	return (
		<Fragment>

			<div
				style={{ width:'512px', height:'512px', position:'relative', margin:'15px 0 5px 0' }}
			>
				{ imageExtension === 'mp4'
				  ? <video ref={ ref } key={ dir + image } width="512" style={{ minWidth:'512px', height:'512px', margin:0, borderRadius:'4px' }} controls autoplay muted>
					  <source src={ dir + image } type="video/mp4" />
					</video>
				  : <img 
				  		key={ dir + image } 
						style={{ minWidth:'512px', height:'512px', cursor:'pointer', margin:0, objectFit:'cover', borderRadius:'4px' }}
						src={ dir + image } 
						onClick={ () => ( setFullScreen( true ) ) } 
					/>
				}

				{ imageExtension !== 'mp4' &&
				<div 
					className="show-on-hover"
					style={{ position:'absolute', top:0, left:0, minWidth:'512px', minHeight:'512px', boxSizing:'border-box' }}
					onClick={ () => ( setFullScreen( true ) ) } 
				>
					<div 
						style={{ userSelect:'none', cursor:'pointer', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', color:'white', textShadow:'0 2px 4px rgba(0,0,0,0.5)', textAlign:'center', boxSizing:'border-box' }}
					>
						<span className="material-icons">open_in_full</span>
						<br/>
						<span style={{ fontSize:'10px', fontWeight:'bold' }} >FULl SCREEN</span>
					</div>
				</div>
				}

			</div>

    		<div>
    		{ files && !fullScreen && files.map( fileName => { 
				const fileExtension = fileName.split(".").pop().toLowerCase()
    			return (
    				fileExtension === 'mp4'
    				? <div
						key={ fileName } 
						onClick={ () => { setImage( fileName ); } }
						style={{ cursor:'pointer', verticalAlign:'top', fontSize:'24px', display:'inline-block', background:'linear-gradient(180deg, rgba(20,30,40,1) 0%, rgba(30,50,70,1) 10%, rgba(96,128,164,1) 100%)', color:'white', width:'80px', height:'80px', margin:'5px 5px 0 0', objectFit:'cover', borderRadius:'4px' }}
    				  >
    				  <span className="material-icons" style={{ fontSize:'48px', color:'white', margin:'16px 0 0 16px' }} >
						play_circle_outline
						</span>
					  </div>
					: <img 
						key={ fileName } 
						onClick={ () => setImage( fileName ) }
						style={{ cursor:'pointer', width:'80px', height:'80px', margin:'5px 5px 0 0', objectFit:'cover', borderRadius:'4px' }}
						src={ dir + fileName } 
					  />
					) 
    		} ) }

			{ files && fullScreen &&
				<ImageInFullScreen
					file={ image } 
					dir={ dir } 
					hideImageFullScreen={ () => ( setFullScreen( false ) ) } 
				/>            
			}

    		</div>

		</Fragment>
	)

}

export default ProductImages