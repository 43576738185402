import React from 'react';

const TdSum = props => (
	<td style={{ 
		fontWeight:'700', 
		padding:'0 10px', 
		border:'1px solid #aaa', 
		margin:'0', 
		borderSpacing:'0',
		height:'28px',
		minHeight:'28px',
		lineHeight:'16px', 
		textAlign:'left',
		verticalAlign:'middle',
	}} >
	{ props.total }
	</td>
)

export default TdSum
