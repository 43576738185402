const ProductsColumns = { 	
	"key":"products", 
	"pageTitle":"Products", 
	"rowTitle":"Product", 
	"newRowTitle":"New Product", 
	"listApi":"products", 
	"rowApi":"product", 
	"newApi":"product", 
	"updateApi":"product-update/", 
	"deleteApi":"product-delete/", 
	"fi":"Tuotteet", 
	"en":"Products", 
	"type":"db", 
	"listRoute":"/products/",
	"formRoute":"/products/",
	"listColumns":[
		{ "key":"img", "fi":"Kuva", "en":"Image", "type":"image", width:'80px', maxWidth:'80px' },
		//{ "key":"collection_title", "fi":"Kokoelma", "en":"Collection" },
		{ "key":"code", "fi":"Koodi", "en":"Style" },
		{ "key":"title", "fi":"Otsikko", "en":"Title" },
		{ "key":"moq", "fi":"MOQ", "en":"MOQ" },
		//{ "key":"display_date", "fi":"Päiväys", "en":"Date", "type":"date" },
	],
	"formRows":[
		{ "key":"title", "fi":"Otsikko", "en":"Title" },
		{ "key":"img", "fi":"Kuva", "en":"Image" },
		{ "key":"display_date", "fi":"Päiväys", "en":"Date", "type":"date" },
		{ "key":"published", "fi":"Julkaistu", "en":"Published", "type":"boolean" },
	],
}

export default ProductsColumns
