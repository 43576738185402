const OrdersColumns = { 	
	"key":"orders", 
	"pageTitle":"ORDERS", 
	"rowTitle":"ORDER", 
	"newRowTitle":"NEW ORDER", 
	"listApi":"orders", 
	"rowApi":"order", 
	"newApi":"order", 
	"updateApi":"order-update/", 
	"deleteApi":"order-delete/", 
	"fi":"TILAUKSET", 
	"en":"ORDERS", 
	"type":"db", 
	"listRoute":"/orders/",
	"formRoute":"/orders/",
	"listColumns":[
		{ "key":"ordinal", "fi":"Order Number", "en":"Order Number" },
		{ "key":"order_date", "fi":"Tilauspäivä", "en":"Date", "type":"date" },
		{ "key":"company_name", "fi":"Yritys", "en":"Company" },
		{ "key":"first_name", "fi":"Etunimi", "en":"First Name" },
		{ "key":"last_name", "fi":"Sukunimi", "en":"Last Name" },
		{ "key":"country", "fi":"country", "en":"Country" },
		{ "key":"amount", "fi":"Yhteensä", "en":"Amount" },
	],
	"formRows":[
		{ "key":"order_date", "fi":"Tilauspäivä", "en":"Order Date", "type":"date" },
		{ "key":"company_name", "fi":"company_name", "en":"company_name" },
		{ "key":"first_name", "fi":"first_name", "en":"first_name" },
		{ "key":"last_name", "fi":"last_name", "en":"last_name" },
		{ "key":"amount", "fi":"amount", "en":"amount" },
	],
}

export default OrdersColumns

