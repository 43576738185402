import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import fi from 'date-fns/locale/fi';
registerLocale( 'fi', fi )

const DateInput = props => {
  //console.log( 'DateInput', typeof props.data[ props.column ], props.data[ props.column ] )
  return (
    <tr>
      <td style={{ whiteSpace:'nowrap' }} >{ props.title }</td>
      <td>
        <DatePicker
          dateFormat="dd.MM.yyyy"
          className="form-input"
          selected={ props.data[ props.column ] }
          onChange={ ( date ) => props.onChange( props.column, date ) }
          locale={ fi }
        />
      </td>
    </tr>
  )
}

export default DateInput
