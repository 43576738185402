const VariantsColumns = { 	
	"key":"variants", 
	"pageTitle":"Variants", 
	"rowTitle":"Variant", 
	"newRowTitle":"New Variant", 
	"listApi":"variants/", 
	"rowApi":"variant", 
	"newApi":"variant", 
	"updateApi":"variant-update/", 
	"deleteApi":"variant-delete/", 
	"fi":"Variantit", 
	"en":"Variants", 
	"type":"db", 
	"listRoute":"/variants/",
	"formRoute":"/variants/",
	"listColumns":[
		{ "key":"sku", "fi":"SKU", "en":"SKU" },
		{ "key":"length", "fi":"Pituus", "en":"Length" },
		{ "key":"lining", "fi":"Fabric", "en":"Fabric" },
		{ "key":"colour", "fi":"Colour", "en":"Colour", "borderRight":"none" },
		{ "key":"html_colour", "fi":"#1", "en":"", "type":"html_colour", "borderLeft":"none" },
		{ "key":"colour2", "fi":"Väri2", "en":"Main fur Colour", "borderRight":"none" },
		{ "key":"html_colour2", "fi":"#2", "en":"", "type":"html_colour", "borderLeft":"none" },
		{ "key":"colour3", "fi":"Väri3", "en":"Fur Details", "borderRight":"none" },
		{ "key":"html_colour3", "fi":"#3", "en":"", "type":"html_colour", "borderLeft":"none" },
		//{ "key":"size", "fi":"Koko", "en":"Size" },
		//{ "key":"moq", "fi":"MOQ", "en":"MOQ" },
		{ "key":"price", "fi":"Hinta", "en":"Price" },
	],
	"formRows":[
		{ "key":"size", "fi":"Koko", "en":"Size" },
		{ "key":"colour", "fi":"Väri", "en":"Colour" },
		{ "key":"length", "fi":"Pituus", "en":"Length" },
		{ "key":"sku", "fi":"SKU", "en":"SKU" },
		{ "key":"moq", "fi":"MOQ", "en":"MOQ" },
		{ "key":"price", "fi":"Hinta", "en":"Price" },
	],
}

export default VariantsColumns

