import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory  } from 'react-router-dom'; 
import parse from 'html-react-parser';
import Request from '../Request/Request';
import Store from '../Store/Store';

const Footer = () => {

	const [ time, setTime ] = useState( null )
	const [ footerFetched, setFooterFetched ] = useState( false )

	const getRows = section => {
		Request.get( 
			'pages-by-section/' + section, 
			result => { 
				//console.log( 'Frontpage getRows', section, result.data )
				Store.set( section, result.data || [] )
				setTime( Date.now() )
			},
			error => { 
				console.error( 'error', error )
			} 
		)
	}

	useEffect( () => {
		if ( !footerFetched ) {
			getRows( 'footer-1' )
			setFooterFetched( true )
		}
	} )

	let footer1 = Store.get( 'footer-1' ) || []

	return (
		<div style={{ width:'100%', overflowX:'hidden', boxSizing:'border-box' }} >

			<div style={{ backgroundColor:'#345', color:'white', padding:'20px', textAlign:'left', fontWeight:'bold', boxSizing:'border-box' }} >
				{ footer1.map( a => {
					return (
						<Link key={ a.id } to={ '/page/' + a.id }  >
						<div style={{ display:'inline-block', margin:'0 0 8px 0', color:'white' }} className="thin-underline-on-hover" >{ a.title }</div><br/>
						</Link>
					)
				} ) }
			</div>

		</div>
	)

}

export default Footer
