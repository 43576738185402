const CollectionsColumns = { 	
	"key":"collections", 
	"pageTitle":"Collections", 
	"rowTitle":"Collection", 
	"newRowTitle":"New Collection", 
	"listApi":"collections", 
	"rowApi":"collection", 
	"newApi":"collection", 
	"updateApi":"collection-update/", 
	"deleteApi":"collection-delete/", 
	"fi":"Kokoelma", 
	"en":"Collections", 
	"type":"db", 
	"listRoute":"/collections/",
	"formRoute":"/collections/",
	"listColumns":[
		{ "key":"title", "fi":"Otsikko", "en":"Title" },
		{ "key":"img", "fi":"Kuva", "en":"Image" },
		{ "key":"published", "fi":"Julkaistu", "en":"Published", "type":"boolean" },
	],
	"formRows":[
		{ "key":"title", "fi":"Otsikko", "en":"Title" },
		{ "key":"img", "fi":"Kuva", "en":"Image" },
		{ "key":"published", "fi":"Julkaistu", "en":"Published", "type":"boolean" },
	],
}

export default CollectionsColumns
