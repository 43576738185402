import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom'; 
import Request from '../Request/Request';
import Store from '../Store/Store';
import Cols from '../Data/ProductsColumns';
import THead from '../Widgets/THead';
import TBody from '../Widgets/TBody';

const ProductsList = props => {

  const history = useHistory()

  const [ currentCollection, setCurrentCollection ] = useState( false )
  const [ time, setTime ] = useState( null )

  let collection = props.match.params.collection

  useEffect( () => {
    if ( collection !== currentCollection ) {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
      getRows( collection )
      setCurrentCollection( collection )
      return () => {}
    }
  } )

  const openRow = id => {
    history.push( '/products/' + collection + '/' + id + '/' )
  }

  const getRows = collection => {
  	Request.get( 
      'products-by-collection-code/' + collection, 
      result => { 
        Store.set( Cols.key + '-' + collection, result.data || [] )
        setTime( Date.now() )
      },
      error => { 
        console.error( 'error', error )
      } 
  	)
  }

  let user = Store.get( 'user' )
  if ( user === null || user === undefined ) {
    history.replace( '/login/' )
  }

  return ( 
    <div style={{ textAlign:'left' }} >

      <h2 style={{ padding:'15px 0 15px 20px', margin:'0', textAlign:'left', fontWeight:'500', boxSizing:'border-box' }}>
        { collection.toUpperCase() }
      </h2>

      <div style={{ padding:'0 0 15px 20px', fontSize:'14px' }} >
        Minimum order quantity (MOQ) per product can include different colours and sizes.
      </div>
      
      <div style={{ padding:'0 0 0 20px' }} >
        <table style={{ fontSize:'12px', width:'290px', minWidth:'290px', maxWidth:'290px', borderCollapse:'collapse' }} >
        <THead
          dataSource={ Cols }
        />
       <TBody 
          dbRows={ Store.get( Cols.key + '-' + collection ) || [] } 
          dataSource={ Cols }
          openRow={ openRow }
        />
        </table>
      </div>

      <div style={{ padding:'15px 0 15px 20px', fontSize:'14px' }} >
        Minimum order quantity (MOQ) per product can include different colours and sizes.
      </div>


    </div>
  )

}

export default ProductsList
