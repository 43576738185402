import React, { Fragment } from 'react';
import Request from '../Request/Request';
import Store from '../Store/Store';
import Cols from '../Data/OrderRowsColumns';
import THead from '../Widgets/THead';
import TBody from '../Widgets/TBody';

class OrderRows extends React.Component {

  constructor( props ) {
    super( props )
    this.state = { 
      tmp:null,
    }
    this.getRows = this.getRows.bind(this)
    this.openRow = this.openRow.bind(this)
    this.newRow = this.newRow.bind(this)
  }

  componentDidMount() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  	this.getRows( 0 )
  }

  getRows( tabIndex ) {
    this.setState( { 
      tabIndex:tabIndex,
    } )
  	Request.get( 
      Cols.listApi + this.props.order_id, 
      result => { 
        Store.set( Cols.key, result.data )
        this.setState( { 
          tmp:null,
        } )
      },
      error => { 
        console.log( 'error', error )
      } 
  	)
  }

  openRow( id ) {
      this.props.history.push( '/'+Cols.key+'/' + id + '/' )
  }

  newRow() {
      this.props.history.push( '/'+Cols.key+'/' + 'new' + '/' )
  }

  render() {

    let user = Store.get( 'user' )
    if ( user === null || user === undefined ) {
      this.props.history.replace( '/login/' )
    }

    return ( 
      <div style={{ textAlign:'left' }} >

        <h2 style={{ padding:'5px 0 15px 0', margin:'0', textAlign:'left', fontWeight:'500', boxSizing:'border-box' }}>
          { Cols.pageTitle }
        </h2>
        <div style={{ padding:'0' }} >
          <table style={{ fontSize:'12px', width:'100%', borderCollapse:'collapse' }} >
          <THead
            dataSource={ Cols  }
          />
         <TBody 
            dbRows={ Store.get( Cols.key ) || [] } 
            dataSource={ Cols }
            //openRow={ this.openRow }
          />
          </table>
        </div>
      {/*
        <div style={{ margin:'13px 10px' }} onClick={ this.newRow } className="open-account-button" >
          { Cols.newRowTitle }
        </div>
      */}
      </div>
    )
  }

}

export default OrderRows
